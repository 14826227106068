import { records } from "./actionTypes";
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
// AUTH
export const login = (username, password) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const responseText = await response.text();
      const contentType = response.headers.get('Content-Type');
      if (!response.ok) {
        // Extract error message from the backend response
        let errorMessage = 'Login failed';
        if (contentType && contentType.includes('application/json')) {
          const errorData = JSON.parse(responseText);
          errorMessage = errorData.error || 'Invalid username or password';
        } else {
          errorMessage = responseText;
        }

        // Dispatch failure with the error message
        dispatch({ type: records.LOGIN_FAILURE, payload: errorMessage });
        return; // Stop further execution
      }
      if (contentType && contentType.includes('application/json')) {
        const data = JSON.parse(responseText);
        if (!data.access_token) {
          throw new Error('Access token missing from response');
        }
        const decodedToken = jwtDecode(data.access_token);
        dispatch({ type: records.LOGIN_SUCCESS,
          // payload: data
          payload: {
            ...data,
            user: decodedToken,
            division: decodedToken.division || ''
          },
        });
      } else {
        throw new Error(`Expected JSON, got: ${responseText}`);
      }
    } catch (error) {
      console.error('Error during login:', error);
      dispatch({ type: records.LOGIN_FAILURE, payload: error.message });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch({ type: records.LOGOUT });
  };
};

export const refreshAccessToken = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.refresh_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No refresh token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.refresh_token}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.LOGIN_SUCCESS, payload: data });
    } catch (error) {
      console.error('Error refreshing token:', error);
      dispatch({ type: records.LOGIN_FAILURE, payload: error.message });
    }
  };
};



export const addDriver = (card_no, full_name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DRIVERS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/drivers`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ card_no, full_name }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.DRIVERS_SUCCESS, payload: data });
      toast.success("Driver added successfully!");
    } catch (error) {
      console.error('Error adding driver:', error);
      dispatch({ type: records.DRIVERS_FAILURE, payload: error.message });
      toast.error("Failed to add driver.");
    }
  };
};

export const disableVehicle = (vehicleId) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DISABLE_VEHICLE_FAILURE, payload: 'No token found' });
      return;
    }

    dispatch({ type: records.DISABLE_VEHICLE_REQUEST });

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vehicles/disable/${vehicleId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.DISABLE_VEHICLE_SUCCESS, payload: data });
      toast.success(`Vehicle with ID ${vehicleId} disabled successfully!`);
    } catch (error) {
      console.error('Error disabling vehicle:', error);
      dispatch({ type: records.DISABLE_VEHICLE_FAILURE, payload: error.message });
      toast.error("Failed to disable vehicle.");
    }
  };
};


export const addVehicle = (imei, tracker_phone, civilian_plate, gk_plate, chasis_no, engine_no, purchase_date, make_id, model_id) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.VEHICLES_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vehicles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ imei, tracker_phone, civilian_plate, gk_plate, chasis_no, engine_no, purchase_date, make_id, model_id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.ADD_VEHICLE_SUCCESS, payload: data });
      toast.success("Vehicle added successfully!");
    } catch (error) {
      console.error('Error adding vehicle:', error);
      dispatch({ type: records.VEHICLES_FAILURE, payload: error.message });
      toast.error("Failed to add vehicle.");
    }
  };
};

export const addRole = (name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.ROLES_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/roles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ name }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.ROLES_SUCCESS, payload: data });
      toast.success("Role added successfully!");
    } catch (error) {
      console.error('Error adding role:', error);
      dispatch({ type: records.ROLES_FAILURE, payload: error.message });
      toast.error("Failed to add role.");
    }
  };
};


export const registerUser = (username, password, role, division) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.ROLES_FAILURE, payload: 'No token found' });
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/register`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
           'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ username, password, role, division }),
      }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorData.message}`);
      }

      const data = await response.json();
      dispatch({ type: records.REGISTER_SUCCESS, payload: data });
      toast.success("User registered successfully!");
    } catch (error) {
      console.error('Error during registration:', error);
      dispatch({ type: records.REGISTER_FAILURE, payload: error.message });
      toast.error("Registration failed. Please try again.");
    }
  };
};

export const resetPassword = (username, password) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/reset_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.PASSWORD_RESET_SUCCESS, payload: data });
      toast.success("Password reset successfully!");
    } catch (error) {
      console.error('Error resetting password:', error);
      dispatch({ type: records.PASSWORD_RESET_FAILURE, payload: error.message });
      toast.error("Failed to reset password.");
    }
  };
};


export const updateUserRole = (username, role) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ username, role }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.USER_ROLE_UPDATE_SUCCESS, payload: data });
      toast.success("User role updated successfully!");
    } catch (error) {
      console.error('Error updating user role:', error);
      dispatch({ type: records.USER_ROLE_UPDATE_FAILURE, payload: error.message });
      toast.error("Failed to update user role.");
    }
  };
};

export const updateUserDivision = (username, division) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ username, division }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.USER_DIVISION_UPDATE_SUCCESS, payload: data });
      toast.success("User division updated successfully!");
    } catch (error) {
      console.error('Error updating user division:', error);
      dispatch({ type: records.USER_DIVISION_UPDATE_FAILURE, payload: error.message });
      toast.error("Failed to update user division.");
    }
  };
};

export const addDivision = (div_name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DIVISIONS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/divisions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ div_name }),
      });

      if (!await handleResponse(response, dispatch, records.DIVISIONS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.ADD_DIVISION_SUCCESS, payload: data });
      toast.success("Division added successfully!");
    } catch (error) {
      console.error('Error adding division:', error);
      dispatch({ type: records.DIVISIONS_FAILURE, payload: error.message });
      toast.error("Failed to add division.");
    }
  };
};

export const editDivision = (id, div_name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DIVISIONS_FAILURE, payload: 'No token found' });
      return;
    }

    try {

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/divisions`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ id, div_name }),
      });

      if (!response.ok) {
        const errorMessage = `Failed to edit division. Status: ${response.status}`;
        dispatch({ type: records.DIVISIONS_FAILURE, payload: errorMessage });
        return;
      }

      const data = await response.json();
      console.log("Received response data: ", data);

      dispatch({ type: records.EDIT_DIVISION_SUCCESS, payload: data });

      toast.success("Division edited successfully!");
    } catch (error) {
      console.error('Error editing division:', error);
      dispatch({ type: records.DIVISIONS_FAILURE, payload: error.message });
      toast.error("Failed to edit division.");
    }
  };
};

export const deleteDivision = (id) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DIVISIONS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/divisions/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch, records.DIVISIONS_FAILURE)) return;

      dispatch({ type: records.DELETE_DIVISION_SUCCESS, payload: id });
      toast.success("Division deleted successfully!");
    } catch (error) {
      console.error('Error deleting division:', error);
      dispatch({ type: records.DIVISIONS_FAILURE, payload: error.message });
      toast.error("Failed to delete division.");
    }
  };
};

export const addSubdivision = (division, subdiv_name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.SUBDIVISIONS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subdivisions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ division, subdiv_name }),
      });

      if (!await handleResponse(response, dispatch, records.SUBDIVISIONS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.ADD_SUBDIVISION_SUCCESS, payload: data });
      toast.success("Subdivision added successfully!");
    } catch (error) {
      console.error('Error adding subdivision:', error);
      dispatch({ type: records.SUBDIVISIONS_FAILURE, payload: error.message });
      toast.error("Failed to add subdivision.");
    }
  };
};

export const editSubdivision = (id, division, subdiv_name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.SUBDIVISIONS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subdivisions/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ division, subdiv_name }),
      });

      if (!await handleResponse(response, dispatch, records.SUBDIVISIONS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.EDIT_SUBDIVISION_SUCCESS, payload: data });
      toast.success("Subdivision edited successfully!");
    } catch (error) {
      console.error('Error editing subdivision:', error);
      dispatch({ type: records.SUBDIVISIONS_FAILURE, payload: error.message });
      toast.error("Failed to edit subdivision.");
    }
  };
};

// Delete subdivision
export const deleteSubdivision = (id) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.SUBDIVISIONS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subdivisions/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch, records.SUBDIVISIONS_FAILURE)) return;

      dispatch({ type: records.DELETE_SUBDIVISION_SUCCESS, payload: id });
      toast.success("Subdivision deleted successfully!");
    } catch (error) {
      console.error('Error deleting subdivision:', error);
      dispatch({ type: records.SUBDIVISIONS_FAILURE, payload: error.message });
      toast.error("Failed to delete subdivision.");
    }
  };
};


// Add department
export const addDepartment = (division, subdiv_name, department_name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DEPARTMENTS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/departments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ division, subdiv_name, department_name }),
      });

      if (!await handleResponse(response, dispatch, records.DEPARTMENTS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.ADD_DEPARTMENT_SUCCESS, payload: data });
      toast.success("Department added successfully!");
    } catch (error) {
      console.error('Error adding department:', error);
      dispatch({ type: records.DEPARTMENTS_FAILURE, payload: error.message });
      toast.error("Failed to add department.");
    }
  };
};

// Edit department
export const editDepartment = (id, division, subdiv_name, department_name) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DEPARTMENTS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/departments/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({ division, subdiv_name, department_name }),
      });

      if (!await handleResponse(response, dispatch, records.DEPARTMENTS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.EDIT_DEPARTMENT_SUCCESS, payload: data });
      toast.success("Department edited successfully!");
    } catch (error) {
      console.error('Error editing department:', error);
      dispatch({ type: records.DEPARTMENTS_FAILURE, payload: error.message });
      toast.error("Failed to edit department.");
    }
  };
};

// Delete department
export const deleteDepartment = (id) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.DEPARTMENTS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/departments/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch, records.DEPARTMENTS_FAILURE)) return;

      dispatch({ type: records.DELETE_DEPARTMENT_SUCCESS, payload: id });
      toast.success("Department deleted successfully!");
    } catch (error) {
      console.error('Error deleting department:', error);
      dispatch({ type: records.DEPARTMENTS_FAILURE, payload: error.message });
      toast.error("Failed to delete department.");
    }
  };
};

export const assignVehicle = (assignmentData) => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.VEHICLES_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vehicle-assignments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify(assignmentData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.ASSIGN_VEHICLE_SUCCESS, payload: data });
      toast.success("Vehicle assigned successfully!");
    } catch (error) {
      console.error('Error assigning vehicle:', error);
      dispatch({ type: records.ASSIGN_VEHICLE_FAILURE, payload: error.message });
      toast.error("Failed to assign vehicle.");
    }
  };
};
export const fetchVehicleHistory = (imei, start_date, end_date) => {
 
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.FETCH_VEHICLE_HISTORY_SUCCESS_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/replay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({imei, start_date, end_date}),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.FETCH_VEHICLE_HISTORY_SUCCESS, payload: data });
      toast.success("Coordinates Fetched successfully!");
    } catch (error) {
      console.error('getting coordinates:', error);
      dispatch({ type: records.FETCH_VEHICLE_HISTORY_SUCCESS_FAILURE, payload: error.message });
      toast.error("Failed to get coordinates.");
    }
  };
};



export const updateVehicleAssignment = (assignmentData) => {

  console.log(assignmentData)
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.VEHICLES_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vehicle-assignments}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
        body: JSON.stringify({assignmentData}),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.UPDATE_VEHICLE_ASSIGNMENT_SUCCESS, payload: data });
      toast.success("Vehicle assignment updated successfully!");
    } catch (error) {
      console.error('Error updating vehicle assignment:', error);
      dispatch({ type: records.UPDATE_VEHICLE_ASSIGNMENT_FAILURE, payload: error.message });
      toast.error("Failed to update vehicle assignment.");
    }
  };
};

export const updateVehicle = (imei,tracker_phone, civilian_plate,gk_plate,chasis_no,engine_no,  purchase_date) => {
  

  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.VEHICLES_FAILURE, payload: 'No token found' });
      return;
    }
  try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vehicles`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`
          },
          
          body: JSON.stringify({ imei,tracker_phone, civilian_plate,gk_plate,chasis_no,engine_no,  purchase_date }),
      });
      if (!response.ok) {
          throw new Error('Failed to update vehicle');
      }
      toast.success("Vehicle updated successfully!");
      dispatch(fetchVehicles());
  } catch (error) {
      dispatch({ type: 'VEHICLES_FAILURE', payload: error.message });
      toast.error("Failed update vehicle.");
  }
 };
};



//fetch 
const handleResponse = async (response, dispatch) => {
  if (response.status === 401) {
    await dispatch(refreshAccessToken());
    return false;
  }
  return response.ok;
};

export const fetchDrivers = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/drivers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch)) return;

      const data = await response.json();
      dispatch({ type: records.DRIVERS, payload: data });
    } catch (error) {
      console.error('Error fetching drivers:', error);
      dispatch({ type: records.DRIVERS_FAILURE, payload: error.message });
    }
  };
};

export const fetchVehicles = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vehicles`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch)) return;

      const data = await response.json();
      dispatch({ type: records.VEHICLES_SUCCESS, payload: data });
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      dispatch({ type: records.VEHICLES_FAILURE, payload: error.message });
    }
  };
};

export const fetchRoles = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/roles`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch)) return;

      const data = await response.json();
      dispatch({ type: records.ROLES_SUCCESS, payload: data });
    } catch (error) {
      console.error('Error fetching roles:', error);
      dispatch({ type: records.ROLES_FAILURE, payload: error.message });
    }
  };
};
// export const fetchDivisions = () => {
//   return async (dispatch, getState) => {
//     const { user } = getState().login;
//     if (!user || !user.access_token) {
//       dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
//       return;
//     }
//
//     try {
//       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/divisions`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${user.access_token}`
//         },
//       });
//
//       if (!await handleResponse(response, dispatch)) return;
//
//       const data = await response.json();
//       dispatch({ type: records.DIVISIONS_SUCCESS, payload: data });
//     } catch (error) {
//       console.error('Error fetching roles:', error);
//       dispatch({ type: records.DIVISIONS_FAILURE, payload: error.message });
//     }
//   };
// };
export const fetchUsers = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch)) return;

      const data = await response.json();
      dispatch({ type: records.USERS_SUCCESS, payload: data });
    } catch (error) {
      console.error('Error fetching users:', error);
      dispatch({ type: records.USERS_FAILURE, payload: error.message });
    }
  };
};


export const fetchDivisions = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/divisions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch, records.DIVISIONS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.DIVISIONS_SUCCESS, payload: data });
    } catch (error) {
      console.error('Error fetching divisions:', error);
      dispatch({ type: records.DIVISIONS_FAILURE, payload: error.message });
    }
  };
};

// Fetch subdivisions
export const fetchSubdivisions = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subdivisions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch, records.SUBDIVISIONS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.SUBDIVISIONS_SUCCESSM, payload: data });
    } catch (error) {
      console.error('Error fetching subdivisions:', error);
      dispatch({ type: records.SUBDIVISIONS_FAILUREM, payload: error.message });
    }
  };
};

// Fetch departments
export const fetchDepartments = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/departments`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!await handleResponse(response, dispatch, records.DEPARTMENTS_FAILURE)) return;

      const data = await response.json();
      dispatch({ type: records.DEPARTMENTS_SUCCESS, payload: data });
    } catch (error) {
      console.error('Error fetching departments:', error);
      dispatch({ type: records.DEPARTMENTS_FAILURE, payload: error.message });
    }
  };
};

export const fetchVehicleAssignments = () => {
  return async (dispatch, getState) => {
    const { user } = getState().login;
    if (!user || !user.access_token) {
      dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vehicle-assignments`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      dispatch({ type: records.FETCH_VEHICLE_ASSIGNMENTS_SUCCESS, payload: data });
    } catch (error) {
      console.error('Error fetching vehicle assignments:', error);
      dispatch({ type: records.FETCH_VEHICLE_ASSIGNMENTS_FAILURE, payload: error.message });
    }
  };
};



export const fetchSubdivisionsByDivision = (divisionId) => async (dispatch, getState) => {
  const { user } = getState().login;
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/division-details/${divisionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      }
    });
    const data = await response.json();
    dispatch({ type: 'FETCH_SUBDIVISIONS_BY_DIVISION_SUCCESS', payload: data });
  } catch (error) {
    dispatch({ type: 'FETCH_SUBDIVISIONS_BY_DIVISION_FAILURE', payload: error.message });
  }
};


//car makes
export const fetchCarMakes = () => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_make`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
    });

    if (!await handleResponse(response, dispatch)) return;

    const data = await response.json();
    dispatch({ type: records.FETCH_CAR_MAKES_SUCCESS, payload: data });
  } catch (error) {
    console.error('Error fetching car makes:', error);
    dispatch({ type: records.FETCH_CAR_MAKES_FAILURE, payload: error.message });
  }
};


export const addCarMake = (make_name) => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_make`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
      body: JSON.stringify({ make_name }),
    });

    if (!await handleResponse(response, dispatch)) return;

    const data = await response.json();
    dispatch({ type: records.ADD_CAR_MAKE_SUCCESS, payload: data });
  } catch (error) {
    console.error('Error adding car make:', error);
    dispatch({ type: records.ADD_CAR_MAKE_FAILURE, payload: error.message });
  }
};

export const updateCarMake = (id, make_name) => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_make`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
      body: JSON.stringify({ id, make_name }),
    });

    if (!await handleResponse(response, dispatch)) return;

    const data = await response.json();
    dispatch({ type: records.UPDATE_CAR_MAKE_SUCCESS, payload: data });
  } catch (error) {
    console.error('Error updating car make:', error);
    dispatch({ type: records.UPDATE_CAR_MAKE_FAILURE, payload: error.message });
  }
};

export const deleteCarMake = (make_id) => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_make/${make_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
    });

    if (!await handleResponse(response, dispatch)) return;

    dispatch({ type: records.DELETE_CAR_MAKE_SUCCESS, payload: make_id });
  } catch (error) {
    console.error('Error deleting car make:', error);
    dispatch({ type: records.DELETE_CAR_MAKE_FAILURE, payload: error.message });
  }
};


export const fetchCarModels = (make_id) => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_make/${make_id}/car_model`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
    });

    if (!await handleResponse(response, dispatch)) return;

    const data = await response.json();
    dispatch({ type: records.FETCH_CAR_MODELS_SUCCESS, payload: data });
  } catch (error) {
    console.error(`Error fetching car models for make id ${make_id}:`, error);
    dispatch({ type: records.FETCH_CAR_MODELS_FAILURE, payload: error.message });
  }
};


export const addCarModel = (modelDetails) => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_model`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
      body: JSON.stringify(modelDetails)
    });

    if (!await handleResponse(response, dispatch)) return;

    const data = await response.json();
    dispatch({ type: records.ADD_CAR_MODEL_SUCCESS, payload: data });
  } catch (error) {
    console.error('Error adding car model:', error);
    dispatch({ type: records.ADD_CAR_MODEL_FAILURE, payload: error.message });
  }
};


export const updateCarModel = (modelId, modelDetails) => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_model/${modelId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
      body: JSON.stringify(modelDetails)
    });

    if (!await handleResponse(response, dispatch)) return;

    const data = await response.json();
    dispatch({ type: records.UPDATE_CAR_MODEL_SUCCESS, payload: data });
  } catch (error) {
    console.error(`Error updating car model with id ${modelId}:`, error);
    dispatch({ type: records.UPDATE_CAR_MODEL_FAILURE, payload: error.message });
  }
};


export const deleteCarModel = (modelId) => async (dispatch, getState) => {
  const { user } = getState().login;
  if (!user || !user.access_token) {
    dispatch({ type: records.LOGIN_FAILURE, payload: 'No token found' });
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/car_model/${modelId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`
      },
    });

    if (!await handleResponse(response, dispatch)) return;

    dispatch({ type: records.DELETE_CAR_MODEL_SUCCESS, payload: modelId });
  } catch (error) {
    console.error(`Error deleting car model with id ${modelId}:`, error);
    dispatch({ type: records.DELETE_CAR_MODEL_FAILURE, payload: error.message });
  }
};

export const resetLoginError = () => ({
  type: records.RESET_LOGIN_ERROR,
});