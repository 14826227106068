// App.js
import { useEffect } from 'react';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './redux/actions/recordAction';
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/drivers";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/vehicles";
import Adduser from "./scenes/Adduser";
import Roles from "./scenes/roles";
import Divisions from "./scenes/divisions";
import Subdivisions from "./scenes/subDivisions";
import Departments from "./scenes/departments";
import VehicleAssignments from "./scenes/vehicleAssignments";
import VehicleHistory from "./scenes/vehicleHistory";
import CarMakes from "./scenes/carMakes";

// import Calendar from "./scenes/calendar";
// import FAQ from "./scenes/faq";
// import Bar from "./scenes/bar";
// import Pie from "./scenes/pie";
// import Line from "./scenes/line";
// import Geography from "./scenes/geography";
import LoginPage from "./scenes/login";
import PrivateRoute from './privateRoute';

import { Provider as StoreProvider } from 'react-redux';
import  store  from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user);

  useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, location.pathname, navigate]);

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem('user')) {
        dispatch(logout());
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StoreProvider store={store}>
        <ToastContainer />
          
          <div className="app">
            {location.pathname !== '/' && <Sidebar />}
            <main className="content">
              {location.pathname !== '/' && <Topbar />}
              <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                <Route path="/drivers" element={<PrivateRoute element={<Team />} />} />
                <Route path="/invoices" element={<PrivateRoute element={<Invoices />} />} />
                <Route path="/vehicles" element={<PrivateRoute element={<Contacts />} />} />
                <Route path="/Adduser" element={<PrivateRoute element={<Adduser />} />} />
                <Route path="/roles" element={<PrivateRoute element={<Roles />} />} />
                <Route path="/divisions" element={<PrivateRoute element={<Divisions />} />} />
                <Route path="/subDivisions" element={<PrivateRoute element={<Subdivisions />} />} />
                <Route path="/departments" element={<PrivateRoute element={<Departments />} />} />
                <Route path="/vehicleAssignments" element={<PrivateRoute element={<VehicleAssignments />} />} />
                <Route path="/vehicle-history/:imei" element={<PrivateRoute element={<VehicleHistory />} />} />
                <Route path="/CarMakes" element={<PrivateRoute element={<CarMakes />} />} />



                

                


                
                {/* <Route path="/faq" element={<PrivateRoute element={<FAQ />} />} />
                <Route path="/bar" element={<PrivateRoute element={<Bar />} />} />
                <Route path="/pie" element={<PrivateRoute element={<Pie />} />} />
                <Route path="/line" element={<PrivateRoute element={<Line />} />} />
                <Route path="/geography" element={<PrivateRoute element={<Geography />} />} /> */}
              </Routes>
            </main>
          </div>
        </StoreProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;



