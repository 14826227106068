import React, { useState } from 'react';
import { Container, TextField, Button, Box, Typography, InputAdornment, useTheme } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { login, resetLoginError } from '../../redux/actions/recordAction';
import { useNavigate } from 'react-router-dom';
import { tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginError = useSelector(state => state.login.error);
  const user = useSelector(state => state.login.user);
  // const userDivision = useSelector(state => state.login.user?.division);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  React.useEffect(() => {
    dispatch(resetLoginError());
  }, [dispatch]);

  const handleLogin = () => {
    dispatch(login(username, password));
  };

  React.useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  // console.log(loginError);
  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Fleet Management System
      </Typography>
      <Box style={{ width: '100%', maxWidth: 360, marginTop: '24px' }}
      
      >
        {/*{loading && <Typography variant="body2" color="info" align="center" gutterBottom>Logging in...</Typography>}*/}
        {/* {loginError && <Typography color="error" variant="body2" align="center" gutterBottom>{loginError}</Typography>} */}
        {loginError && loginError !== "HTTP error! Status: 405" && (
          <Typography color="error" variant="body2" align="center" gutterBottom>
            {loginError}
          </Typography>
        )}
        <TextField
          fullWidth
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: '16px', ml: 2, flex: 1  }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: '16px' }}
        />
        {/* {loginError && <Typography color="error" variant="body2">{loginError}</Typography>} */}
        <Button
          fullWidth
          
          onClick={handleLogin}
          
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            
          }}
        >
         
          Log In
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;
