import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {jwtDecode} from "jwt-decode";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CarRentalIcon from '@mui/icons-material/CarRental';

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 768);
    const [selected, setSelected] = useState("Dashboard");
    const [userRole, setUserRole] = useState("");
    const [username, setUsername] = useState("");
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user"));
        if (token && token.access_token) {
            const decodedToken = jwtDecode(token.access_token);
            setUserRole(decodedToken.role);
            setUsername(decodedToken.username);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsCollapsed(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed} breakPoint={null}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={<MenuOutlinedIcon />}
                        style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
                    >
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography fontWeight="bold" variant="h3" color={colors.grey[100]}>
                                    FMIS
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px" textAlign="center">
                            <Typography variant="h4" color={colors.grey[100]} fontWeight="bold">
                                Username: {username}
                            </Typography>
                        </Box>
                    )}

                    {/* MENU ITEMS */}
                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item title="Dashboard" to="/dashboard" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

                        <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                            Data
                        </Typography>
                        <Item title="Drivers" to="/drivers" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected} />
                        <Item title="Vehicles" to="/vehicles" icon={<DirectionsCarIcon />} selected={selected} setSelected={setSelected} />
                        <Item title="Vehicles Assignment" to="/vehicleAssignments" icon={<CarRentalIcon />} selected={selected} setSelected={setSelected} />

                        {!isMobile && userRole === "admin" && (
                            <>
                                <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                                    Settings
                                </Typography>
                                <Item title="Roles" to="/roles" icon={<SupervisedUserCircleIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Divisions" to="/divisions" icon={<LocationCityIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Sub-Divisions" to="/subDivisions" icon={<CorporateFareIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Departments" to="/departments" icon={<MeetingRoomIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Car Makes" to="/CarMakes" icon={<DirectionsCarIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="User Management" to="/Adduser" icon={<PersonOutlinedIcon />} selected={selected} setSelected={setSelected} />
                            </>
                        )}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
