import React, { useEffect, useState } from 'react';
import { Box, useTheme, Tooltip, IconButton, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehicleAssignments, fetchDivisions, fetchSubdivisions, fetchDepartments } from '../../redux/actions/recordAction';
import AssignVehicleModal from './AssignVehicleModal';
import EditIcon from '@mui/icons-material/Edit';

const VehicleAssignments = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const vehicleAssignments = useSelector((state) => state.record.vehicleAssignments) || [];
    const divisions = useSelector((state) => state.record.divisions) || [];
    const subdivisions = useSelector((state) => state.record.subdivisions) || [];
    const departments = useSelector((state) => state.record.departments) || [];
    const userDivision = useSelector((state) => state.login.user?.division) || [];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [openAssign, setOpenAssign] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const isDarkMode = theme.palette.mode === 'dark';
    const divison = "None";

    useEffect(() => {
        dispatch(fetchVehicleAssignments());
        dispatch(fetchDivisions());
        dispatch(fetchSubdivisions());
        dispatch(fetchDepartments());
    }, [dispatch]);

    const handleAssignVehicle = () => {
        setSelectedAssignment(null);
        setOpenAssign(true);
    };

    const handleEditAssignment = (assignment) => {
        setSelectedAssignment(assignment);
        setIsEditing(true);
        setOpenAssign(true);
    };

    const handleCloseAssignModal = () => {
        setOpenAssign(false);
        setSelectedAssignment(null);
        setIsEditing(false);
    };

    const processedVehicleAssignments = vehicleAssignments
        .filter((assignment) => {
            // If userDivision is null, display all vehicles
            if (userDivision === divison) {
                return true;
            }
            return userDivision === assignment?.assigned_division?.name;
        })
        .map((assignment, index) => ({
            ...assignment,
            id: index + 1, // Set incrementing ID
            vehicle: assignment?.vehicle?.plate,
            assigned_division: assignment?.assigned_division?.name,
            assigned_subdivision: assignment?.assigned_subdivision?.name,
            assigned_department: assignment?.assigned_department?.name,
        }));

    const columns = [
        { field: "id", headerName: "ID", flex: 0.5 },
        { field: "vehicle", headerName: "Vehicle", flex: 1 },
        { field: "assigned_division", headerName: "Assigned Division", flex: 1 },
        { field: "assigned_subdivision", headerName: "Assigned Subdivision", flex: 1 },
        { field: "assigned_department", headerName: "Assigned Department", flex: 1 },
        { field: "assigned_category", headerName: "Assigned Category", flex: 1 },
        { field: "assigned_person", headerName: "Assigned Person", flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Edit Vehicle">
                    <IconButton sx={{ color: isDarkMode ? 'white' : 'black' }} onClick={() => handleEditAssignment(params.row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            ),
        }
    ];

    const mobileColumns = [
        { field: "vehicle", headerName: "Vehicle", flex: 1 },
        { field: "assigned_division", headerName: "Assigned Division", flex: 1 },
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Vehicle Assignments" subtitle="List of Vehicle Assignments" />
            </Box>
            <Box m="20px 0 0 0" height="75vh" sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                },
            }}>
                <DataGrid
                    rows={processedVehicleAssignments}
                    columns={isMobile ? mobileColumns : columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
            <AssignVehicleModal
                open={openAssign}
                handleClose={handleCloseAssignModal}
                vehicle={selectedAssignment}
                divisions={divisions}
                subdivisions={subdivisions}
                departments={departments}
                isEditing={isEditing}
            />
        </Box>
    );
};

export default VehicleAssignments;
