// actionTypes.js
export const records = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
  DRIVERS: "DRIVERS",
  DRIVERS_SUCCESS: "DRIVERS_SUCCESS",
  DRIVERS_FAILURE: "DRIVERS_FAILURE",
  ADD_VEHICLE_SUCCESS: "ADD_VEHICLE_SUCCESS",
  VEHICLES_SUCCESS: "VEHICLES_SUCCESS",
  VEHICLES_FAILURE: "VEHICLES_FAILURE",
  ROLES_SUCCESS: "ROLES_SUCCESS",
  ROLES_FAILURE: "ROLES_FAILURE",
  REGISTER_SUCCESS : "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_FAILURE: "USERS_FAILURE",
  USER_ROLE_UPDATE_SUCCESS: "USER_ROLE_UPDATE_SUCCESS",
  USER_ROLE_UPDATE_FAILURE: "USER_ROLE_UPDATE_FAILURE",
  USER_DIVISION_UPDATE_SUCCESS: "USER_DIVISION_UPDATE_SUCCESS",
  USER_DIVISION_UPDATE_FAILURE: "USER_DIVISION_UPDATE_FAILURE",
  DIVISIONS_SUCCESS: "DIVISIONS_SUCCESS",
  DIVISIONS_FAILURE: "DIVISIONS_FAILURE",
  ADD_DIVISION_SUCCESS: "ADD_DIVISION_SUCCESS",
  EDIT_DIVISION_SUCCESS: "EDIT_DIVISION_SUCCESS",
  DELETE_DIVISION_SUCCESS: "DELETE_DIVISION_SUCCESS",
  SUBDIVISIONS_SUCCESS: "SUBDIVISIONS_SUCCESS",
  ADD_SUBDIVISION_SUCCESS: "ADD_SUBDIVISION_SUCCESS",
  EDIT_SUBDIVISION_SUCCESS: "EDIT_SUBDIVISION_SUCCESS",
  DELETE_SUBDIVISION_SUCCESS: "DELETE_SUBDIVISION_SUCCESS",
  SUBDIVISIONS_FAILURE: "SUBDIVISIONS_FAILURE",
  DEPARTMENTS_SUCCESS: "DEPARTMENTS_SUCCESS",
  DEPARTMENTS_FAILURE: "DEPARTMENTS_FAILURE",
  ADD_DEPARTMENT_SUCCESS: "ADD_DEPARTMENT_SUCCESS",
  EDIT_DEPARTMENT_SUCCESS: "EDIT_DEPARTMENT_SUCCESS",
  DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
  ASSIGN_VEHICLE_SUCCESS: "ASSIGN_VEHICLE_SUCCESS",
  ASSIGN_VEHICLE_FAILURE: "ASSIGN_VEHICLE_FAILURE",
  DISABLE_VEHICLE_SUCCESS: "DISABLE_VEHICLE_SUCCESS",
  DISABLE_VEHICLE_FAILURE: "DISABLE_VEHICLE_FAILURE",
  DISABLE_VEHICLE_REQUEST: "DISABLE_VEHICLE_REQUEST",
  FETCH_VEHICLE_ASSIGNMENTS_SUCCESS: 'FETCH_VEHICLE_ASSIGNMENTS_SUCCESS',
  FETCH_VEHICLE_ASSIGNMENTS_FAILURE: 'FETCH_VEHICLE_ASSIGNMENTS_FAILURE',
  UPDATE_VEHICLE_ASSIGNMENT_SUCCESS: 'UPDATE_VEHICLE_ASSIGNMENT_SUCCESS',
  UPDATE_VEHICLE_ASSIGNMENT_FAILURE: 'UPDATE_VEHICLE_ASSIGNMENT_FAILURE',
  FETCH_SUBDIVISIONS_SUCCESS: 'FETCH_SUBDIVISIONS_SUCCESS',
  FETCH_SUBDIVISIONS_FAILURE: 'FETCH_SUBDIVISIONS_FAILURE',
  SUBDIVISIONS_SUCCESSM: 'SUBDIVISIONS_SUCCESSM',
  SUBDIVISIONS_FAILUREM: 'SUBDIVISIONS_FAILUREM',
  DELETE_SUBDIVISION_SUCCESSM: "DELETE_SUBDIVISION_SUCCESSM",
  FETCH_VEHICLE_HISTORY_SUCCESS: "FETCH_VEHICLE_HISTORY_SUCCESS",
  FETCH_VEHICLE_HISTORY_SUCCESS_FAILURE: "FETCH_VEHICLE_HISTORY_SUCCESS_FAILURE",
  FETCH_CAR_MAKES_SUCCESS: 'FETCH_CAR_MAKES_SUCCESS',
  ADD_CAR_MAKE_SUCCESS: 'ADD_CAR_MAKE_SUCCESS',
  UPDATE_CAR_MAKE_SUCCESS: 'UPDATE_CAR_MAKE_SUCCESS',
  DELETE_CAR_MAKE_SUCCESS: 'DELETE_CAR_MAKE_SUCCESS',
  FETCH_CAR_MODELS_SUCCESS: 'FETCH_CAR_MODELS_SUCCESS',
  FETCH_CAR_MODELS_FAILURE: 'FETCH_CAR_MODELS_FAILURE',
  ADD_CAR_MODEL_SUCCESS: 'ADD_CAR_MODEL_SUCCESS',
  UPDATE_CAR_MODEL_SUCCESS: 'UPDATE_CAR_MODEL_SUCCESS',
  DELETE_CAR_MODEL_SUCCESS: 'DELETE_CAR_MODEL_SUCCESS',
  DELETE_CAR_MODEL_FAILURE: 'DELETE_CAR_MODEL_FAILURE',
  ADD_CAR_MODEL_FAILURE: 'ADD_CAR_MODEL_FAILURE',
  UPDATE_CAR_MODEL_FAILURE:'UPDATE_CAR_MODEL_FAILURE',
  FETCH_CAR_MAKES_FAILURE:"FETCH_CAR_MAKES_FAILURE",
  RESET_LOGIN_ERROR: 'RESET_LOGIN_ERROR',

  
  










};
